.main-header-div {
    background-color: var(--light-color);
    position: sticky;
    top: 0;
    z-index: 1;
}
.main-header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 60px;
}
.header-profile {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.header-profile .notification-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-profile .notification-icon:hover {
    background: var(--secondary);
    border-radius: 50%;
    color: #f1f1f1;
}
.logo-main-div {
    display: flex;
    align-items: center;
}
.logo-main-div h4 {
    color: var(--heading-color);
    margin-left: 10px;
    font-size: 24px;
}
.inner-wrapper-profile {
    display: flex;
    align-items: center;
    gap: 10px;
}
.profile-icon {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}
.profile-content h4 {
    color: var(--heading-color);
    font-size: 18px;
    margin-bottom: 4px;
}
.profile-content p {
    color: var(--profile-text);
    font-size: 14px;
    text-align: left;
}

/* dropdown css */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown button */
.dropdown-button {
    background-color: var(--light-color);
    padding: 10px 0px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
}

/* Hidden dropdown content */
.dropdown-content {
    display: none;
    margin-top: 20px;
    background: #fff;
    min-width: 160px;
    position: absolute;
    min-width: 219px;
    top: 60px;
    left: 0px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

/* Dropdown items */
.dropdown-content a {
    color: var(--dark-text);
    padding: 12px 16px;
    border-radius: 4px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}
.dropdown-content {
    display: none;
}

.dropdown-content.show {
    display: block;
}

.logo-main-div .menu-icon {
    display: none !important;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .main-header-flex {
        padding: 18px 30px;
    }
    .logo-main-div .menu-icon {
        display: block !important;
    }
    .logo-icon {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .dropdown-content {
        display: none;
        margin-top: 20px;
        background: #fff;
        min-width: 160px;
        position: absolute;
        min-width: 219px;
    }
}
@media screen and (max-width: 768px) {
    .main-header-flex {
        padding: 12px 20px;
    }
}

@media screen and (max-width: 576px) {
    .logo-main-div h4 {
        font-size: var(--heading-size);
    }
    .dropdown-content {
        min-width: 174px;
        left: 39px;
    }
    .profile-content p {
        font-size: var(--light-text);
    }
    .profile-content h4 {
        font-size: var(--sub-text);
    }
    .main-header-flex {
        padding: 10px 20px;
    }
}

.doctor-dropdown {
    position: relative;
    margin-left: auto;
    margin-right: 20px;
}

.doctor-dropdown input {
    padding: 5px;
    color: black;
    background-color: azure;
}

.doctor-dropdown-button {
    padding: 10px;
    cursor: pointer;
}

.doctor-dropdown-content {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1;
    display: none; /* Hide by default */
}

.doctor-dropdown-content .doctor-dropdown-item {
    padding: 8px;
    cursor: pointer;
}

.doctor-dropdown-content .doctor-dropdown-item:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}

.doctor-dropdown-content {
    display: block; /* Show dropdown on hover */
}
