.standard-referral {
    max-width: 1000px;
    margin: auto;
}
.standard-referral .add-form .heading-border-container {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 1rem 1rem 0rem 1rem;
    position: relative;
    margin-top: 1rem;
}

.standard-referral .add-form .heading-border-container .border-heading {
    position: absolute;
    top: -10px;
    left: 11px;
    background-color: white;
    padding: 0 5px;
}
