/*.main-add-div {
    margin-top: 30px;
    padding: 0px 60px;
}*/

.add-heading h4 {
    color: var(--heading-color);
    font-size: var(--heading-size);
    font-weight: 500;
}
.add-heading {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
}
.info-heading h4,
.consult-div h4 {
    font-size: var(--para-size);
    color: var(--sub-heading);
    font-weight: 500;
}
.consult-div {
    display: flex;
    gap: 30px;
    height: 100%;
    margin-bottom: 20px;
}
.toggle-div {
}
.toggle-icon {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 8px;
}
.heading-referral h4 {
    font-size: var(--para-size);
    color: var(--sub-heading);
    font-weight: 500;
}
.add-form {
    background: var(--light-color);
    padding: 20px 33px;
    border-radius: 4px;
    /*margin-bottom: 20px;*/
}

.main-fields .field-wrap {
    display: flex;
    flex-direction: column;
    width: 33.33%;
}
.main-fields {
    display: flex;
    gap: 30px;
    height: 100%;
    margin-bottom: 20px;
}

.heading-referral {
    margin-bottom: 15px;
}
.consult-div .field-wrap {
    display: flex;
    flex-direction: column;
    width: 33.33%;
}
.consult-div .field-wrap Select#gender {
    padding: 20px;
    border: 1px solid var(--border-color);
    margin-top: 8px;
    width: 100%;
}

.docs p {
    color: #666666;
    margin-top: 4px;
    font-size: 12px;
}
.upload-files-div {
    display: grid;
    grid-template-columns: repeat(2, 49%);
    gap: 1rem;
    width: 100%;
    overflow-x: hidden;
    margin-top: 20px;
    max-height: 120px;
}
.upload-files {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 4px;
}
.file-content h5 {
    font-size: var(--sub-text);
    color: var(--heading-color);
    font-weight: 600;
    margin-bottom: 4px;
    cursor: pointer;
    text-decoration: underline;
}
.file-content p {
    font-size: var(--light-text);
    color: var(--heading-color);
    font-weight: 500;
}
.pdf-img {
    display: flex;
    align-items: center;
    gap: 15px;
}
.file-size p {
    border: 1px solid var(--border-color);
    padding: 4px 8px;
    white-space: nowrap;
}
.note-div {
    margin-top: 20px;
}
.note-div textarea {
    height: 80px;
    margin-top: 10px;
    width: 100%;
    border-color: var(--border-color);
    border-radius: 4px;
    resize: none;
}
.note-div textarea::placeholder {
    padding: 8px 15px;
    color: #666666;
    font-family: 'Roboto';
}
.bottom-btn-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.draft-btn {
    display: flex;
    gap: 10px;
}
.bottom-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-box {
    display: flex;
    /*justify-content: space-between;*/
    gap: 1rem;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-input {
    width: 100%;
    max-width: 400px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

.search-input:focus {
    border-color: #007bff;
}

.pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
/*@media screen and (max-width: 1200px) {
    .main-add-div {
        margin-top: 30px;
        padding: 0px 30px;
    }
}*/
@media screen and (max-width: 992px) {
    .main-fields {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .consult-div {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .main-fields .field-wrap {
        width: 100%;
    }
    .consult-div .field-wrap {
        width: 100%;
    }
    .upload-files {
        width: 100%;
    }
    .upload-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 576px) {
    .field-wrap input {
        padding: 12px !important;
    }
    .field-wrap select {
        padding: 12px !important;
    }
    /*.main-add-div {
        padding: 0px 20px;
    }*/
    .add-heading h4 {
        font-size: var(--para-size);
    }
    .bottom-btn-group {
        flex-direction: column;
    }
    .patient-submit-btn {
        margin-top: 15px;
    }
}
